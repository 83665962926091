.aboutme {
	padding: 0 0 50px 0;
	position: relative;     /* background-color: #000; */
}

.aboutme-bx {
	background: #151515;
	border-radius: 64px;
	text-align: center;
	padding: 60px 50px;
	margin-top: 50px;
}

.aboutme h2 {
	font-size: 45px;
	font-weight: 700;
}

.aboutme p {
	color: #B8B8B8;
	font-size: 18px;
	letter-spacing: 0.8px;
	line-height: 1.5em;
	margin: 14px 0 75px 0;
}

.aboutme-slider {
	width: 80%;
	margin: 0 auto;
	position: relative;
}

.item {
	padding-right: 20px;
}

.aboutme .img-container img {
	object-fit: cover;
	position: relative;
	width: 200px;
	height: 200px;
	border-radius: 50%;
	z-index: 2;
	background-color: linear-gradient(to left, #12c2e9, #c471ed, #f64f59);
}

.aboutme .img-container h5 {
	margin-top: 15px;
}

.skill-slider .item img {
	width: 50%;
	margin: 0 auto 15px auto;
}

.background-image-left {
	top: 28%;
	position: absolute;
	bottom: 0;
	width: 40%;
	z-index: -4;
}

.background-image-right {
	top: 50%;
	position: absolute;
	bottom: 0;
	width: 40%;
	z-index: -4;
}

@media only screen and (max-width:480px) {

	.aboutme .img-container img {
		position: absolute;
		width: 200px;
		height: 200px;
		border-radius: 50%;
		z-index: 2;
		background-color: linear-gradient(to left, #12c2e9, #c471ed, #f64f59);
	}

	.aboutme .img-container h5 {
		position: absolute;
		margin-top: 14rem;
	}
	

	.aboutme p {
		padding-top: 14rem;
        padding-left: 0 !important;
		font-size: 16px;
		line-height: 1.3em;
		margin: 10px 0 40px 0;
	}

}