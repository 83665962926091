.item img {
    border-radius: 50%; /* make the image round */
    max-width: 100px; /* set the maximum width of the image to 100 pixels */
    max-height: 100px; /* set the maximum height of the image to 100 pixels */
}

.item {
  position: relative;
  margin-top: 30px;
  height: 100%;
}
  
.item .people {
    position: relative;
    margin-top: 20px;
}